<template>
  <div class="loginPage">
    <HeadInfo :is-login="true" />
    <div class="loginMain">
      <div class="loginForget">
        <div>
          <img
            src="../../assets/img/forgotuser.png"
            width="216"
            alt="forgotuser"
          >
        </div>
        <div>
          <div class="loginTitle">
            {{ $g("ResetPassword") }}
          </div>
        </div>
      </div>
      <div class="loginContent">
        <k-alert-msg
          ref="lblMsg"
          :is-html="true"
          :display-time="true"
        />
        <KValidationObserver
          ref="observer"
          v-if="verifyStatus"
        >
          <k-form-group
            label-class="require-mark"
            label-suffix="newPassword"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, password: true, max: 256 }"
              v-slot="{ errors }"
              slim
              name="newPassword"
            >
              <k-alert
                :show="errors.length > 0"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="newPassword"
                v-model="form.password"
                type="password"
                :aria-label="$g('newPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="confirmPassword"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, confirmed: 'newPassword', max: 256 }"
              v-slot="{ errors }"
              slim
              name="confirmPassword"
            >
              <k-alert
                :show="errors.length > 0"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="confirmPassword"
                v-model="confirmPassword"
                @paste.native.capture.prevent="() => false"
                type="password"
                :aria-label="$g('confirmPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div
              class="alert alert-warning"
              v-if="isManMachine"
            >
              {{ $g("validate.required") }}
            </div>
            <ReCaptcha
              @validate="validate"
              @expired="resetCaptcha"
              style="margin-bottom: 25px"
            />
          </k-form-group>
        </KValidationObserver>

        <k-form-group
          v-if="verifyStatus"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapBtnCenter"
        >
          <k-button
            type="button"
            @click="save"
          >
            save
          </k-button>
        </k-form-group>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import {
  changePassWordWithCode,
  changeReserPasswordVerifyCode,
} from "@/api/login";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import { storeage, ReCaptcha, repResult } from "fe-infrastractures";
import { login, setUserType } from "@/api/user";
import { getSysGeneralInfo } from "@/api/app";

export default {
  components: {
    HeadInfo,
    FooterInfo,
    ReCaptcha,
  },
  data(){
    return {
      description: "",
      isManMachine: false,
      isValidateRecaptcha: false,
      pageName: "ResetPassword",
      form: {
        userName: "",
        password: "",
        code: "",
      },
      confirmPassword: "",
      localEnv: storeage.getSystemGeneralInfo()?.isLocal,
      verifyStatus: this.$route.query.verifyStatus != 3,
    };
  },
  methods: {
    async save(){
      const isValid = await this.validateBeforeSave();
      if(isValid){
        changePassWordWithCode(this.form).then((resp) => {
          if(resp.data.status == repResult.success){
            this.onLogin();
          } else {
            this.$refs.lblMsg.message(repResult.faild, resp.data.info);
          }
        });
      }
    },
    validate(success){
      this.isValidateRecaptcha = success;
      if(this.localEnv === false){
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha(){
      this.isValidateRecaptcha = false;
    },

    async validateBeforeSave(){
      var isValid = await this.$refs.observer.validate();
      if(this.localEnv === false){
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = isValid && this.isValidateRecaptcha;
      }
      return isValid;
    },
    showMessage(){
      if(!this.verifyStatus){
        this.$refs.lblMsg.message(repResult.faild, this.$route.params.message);
      }
    },
    doDispatch(type, val){
      return this.$store.dispatch(type, val);
    },
    onLogin(){
      login(this.form.userName, this.form.password)
          .then((res) => {
            if(res.data.isPasswordExpired){
              this.$router.push({
                name: "validate-reset-password",
                query: {
                  code: res.data.resetPasswordCode,
                  username: this.form.username,
                },
              });
            } else {
              const tokenInfo = {
                token: res.data.token,
                tokenKey: res.data.refreshToken,
              };
              this.doDispatch("user/saveToken", tokenInfo).then((m) => {
                this.doDispatch("user/getUser").then((ref) => {
                  setUserType(ref.userType);
                  this.$router.push({ name: "home" });
                });
              });
            }
          })
          .catch((err) => {
            this.$router.push({ name: "login" });
          });
    },
    initSysGeneralInfo(){
      getSysGeneralInfo().then((resp) => {
        if(resp.data){
          storeage.setSystemGeneralInfo(resp.data);
        }
      });
    },
  },
  mounted(){
    this.initSysGeneralInfo();
    this.form.userName = this.$route.query.username;
    this.form.code = this.$route.query.code;
    this.showMessage();
  },
  beforeRouteEnter: async (to, from, next) => {
    let queryPost = { userName: to.query.username, code: to.query.code };
    let data = await changeReserPasswordVerifyCode(queryPost);
    if(data.data.status == 3){
      to.query.verifyStatus = 3;
      to.params.message = data.data.info;
    }
    next();
  },
};
</script>
